import store from "./store";
// import utils from "./utils";
// import broadcast from "./broadcast";
import rootScope from "./rootScope";
import utils from "./utils";
import countriesCodes from "./countriesCodes";
// import plugins from "./plugins";



var sharedCoords, pickerCoords, purl, parameters, paramsObject, url404 = false, lang;

// console.info(plugins);
//
// function resolveRoute(purl) {
//     var t;
//     return (t = /^\/(-?\d+\.\d+)\/(-?\d+\.\d+)(?:\/(meteogram|airgram|waves|wind))?(?:\/(\w+)-([^/]+))?/.exec(purl)) ? {
//         plugin: "detail",
//         params: {
//             lat: +t[1],
//             lon: +t[2],
//             source: "url",
//             display: t[3]
//         }
//     } : (t = /^\/distance\/?(vfr|ifr|car|boat|airgram)?\/?(.*)/.exec(purl)) ? {
//         plugin: rootScope.isMobileOrTablet ? "distance" : "rplanner",
//         params: rootScope.isMobileOrTablet ? t[2] || "" : {
//             coords: t[2] || "",
//             view: t[1] || "elevation"
//         }
//     } : (t = /\/webcams\/?([^/]+)?\/?(.+)?$/.exec(purl)) ? t[1] ? "add" === t[1] ? {
//         plugin: "webcams-add"
//     } : "edit" === t[1] ? {
//         plugin: "webcams-edit",
//         params: {
//             id: t[2],
//             requestedByRouter: !0
//         }
//     } : "remove" === t[1] ? {
//         plugin: "webcams-remove",
//         params: {
//             id: t[2],
//             requestedByRouter: !0
//         }
//     } : "embed" === t[1] ? {
//         plugin: "webcams-embed",
//         params: {
//             id: t[2]
//         }
//     } : {
//         plugin: "webcams-detail",
//         params: {
//             id: t[1],
//             requestedByRouter: !0
//         }
//     } : {
//         plugin: "webcams",
//         params: {
//             requestedByRouter: !0
//         }
//     } : (t = /^\/([^/]+)\/(-?\d+\.\d+)\/(-?\d+\.\d+)$/.exec(purl)) && t[1]in plugins ? {
//         plugin: t[1],
//         params: {
//             lat: +t[2],
//             lon: +t[3],
//             source: "url"
//         }
//     } : (t = /^\/([^/]+)\/([^/]+)$/.exec(purl)) && t[1]in plugins ? {
//         plugin: t[1],
//         params: t[2]
//     } : (t = /^\/([^/]+)$/.exec(purl)) && t[1]in plugins ? {
//         plugin: t[1]
//     } : (t = /^\/(\w\w\w\w)$/.exec(purl)) ? {
//         plugin: "airport",
//         params: {
//             icao: t[1].toUpperCase(),
//             source: "url"
//         }
//     } : void (purl && purl.length > 3 && (url404 = true))
// }

function parseSearch(parameters) {
    let setcountry = false;
    for (var param, arrayParams = parameters.split(","), nparam = 0; nparam < arrayParams.length; nparam++) {
        var arrayRegex;

        param = arrayParams[nparam];

        if ( /^-?\d+\.\d+$/.test(param) && /^-?\d+\.\d+$/.test(arrayParams[nparam + 1]) && /^\d+$/.test(arrayParams[nparam + 2]) ) {
            sharedCoords = {
                lat: parseFloat(param),
                lon: parseFloat(arrayParams[nparam + 1]),
                zoom: parseInt(arrayParams[nparam + 2])
            };
            nparam += 2
        }
        rootScope.products.includes(param) && store.set("product", param);
        rootScope.levels.includes(param) && store.set("level", param);
        if (rootScope.overlays.includes(param)) {
            store.set("overlay", param);
        }
            // rootScope.acTimes.includes(param) && store.set("acTime", param);


            // Fecha y hora
        arrayRegex = /^(\d\d\d\d)-(\d\d)-(\d\d)-(\d\d)$/.exec(param);
        if ( arrayRegex )
            store.set("timestamp", Date.UTC(+arrayRegex[1], +arrayRegex[2] - 1, +arrayRegex[3], +arrayRegex[4], 0, 0, 0));

        // arrayRegex = /^(a:?[a-zA-Z0-9]{5})/.exec(param);
        // if ( arrayRegex )
        //     rootScope.customAnimation = arrayRegex[1];

        // Posición del picker
        // arrayRegex = /^m:([a-zA-Z0-9]{5,})/.exec(param);
        // if (arrayRegex)
        //     o = utils.str2latLon(arrayRegex[1]);

        // Idioma
        // arrayRegex = /^lang:([a-z]{2}(?:-[A-Z]{2})?)/.exec(param);
        arrayRegex = /^lang:([a-z]{2}_[A-Z]{2})/.exec(param);
        if (arrayRegex) {
            lang = arrayRegex[1];
            const country = lang.split("_")[1];
            if (! utils.isValidLang(lang)) {
                const defLang = utils.defaultLang(lang);
                if ( defLang !== undefined )
                    lang = defLang;
            }

            store.set("lang", lang);

            if(utils.isValidCountry(country)) {
                store.set("country", country);
                setcountry = true;
            }
        }

        // Tipo de isolineas
        arrayRegex = /^isolines:([a-z0-9]{3,})/.exec(param);
        if (arrayRegex)
            store.set("isolines", arrayRegex[1]);

        // Mapa base
        arrayRegex = /^map:([a-z0-9]{3,})/.exec(param);
        if (arrayRegex)
            store.set("map", arrayRegex[1]);

        // arrayRegex = /^d:picker/.exec(param);
        // if ( arrayRegex)
        //     o = r;

        arrayRegex = /^(\w{3,20}):(\S+)/.exec(param);
        if ( arrayRegex ) {
            var d = store.getProperty(arrayRegex[1]);
            if ( d && ! d.save && !d.sync )
                store.set(arrayRegex[1], arrayRegex[2])
        }
    }

    // Si se ha pasado un país como parámetro pero no las coordenadas
    if(typeof sharedCoords === "undefined" && setcountry){
        const countrycode = store.get("country");
        const country = countriesCodes[countrycode];
        sharedCoords = {lat: country.lat, lon: country.lon, zoom: rootScope.isMobileOrTablet ? country.zoom_mv : country.zoom_pc};
    }

    return {
        sharedCoords: sharedCoords,
        pickerCoords: pickerCoords
    }
}

// Para detectar el idioma en el dominio meteored.com
purl = decodeURIComponent(window.location.pathname);

const pathsplit = window.location.pathname.split("/");

let urllang = null;

if (pathsplit.length > 1){
    urllang = pathsplit[pathsplit.length - 2].replace("-","_");

    if (! utils.isValidLang(urllang)) {
        const defLang = utils.defaultLang(urllang+"_KK");
        urllang = null;
        if ( defLang !== undefined )
            urllang = defLang;
    }

    if (urllang)
        store.set("lang", urllang);
}

let doclang = null;
if (document.documentElement.lang && ! urllang){
    doclang = document.documentElement.lang;

    if (! utils.isValidLang(doclang)) {
        const defLang = utils.defaultLang(doclang+"_KK");
        doclang = null;
        if ( defLang !== undefined )
            doclang = defLang;
    }

    if (doclang)
        store.set("lang", doclang);
}

var uriComponent = window.location.search.substring(1).replace(/\?.*$/, "");

if (!uriComponent)
    uriComponent = window.location.hash.substring(1).replace(/#.*$/, "");

parameters = ( parameters = decodeURIComponent(uriComponent) || "" ).replace(/&utm_.*$/, "");

if ( parameters )
{
    if ( /\S+=\S+/.test(parameters) ) {
        let params = function (parameters) {
            for (var paramsArray = parameters.split("&"), paramsObject = {}, i = 0; i < paramsArray.length; i++) {
                var element = paramsArray[i].split("=");
                paramsObject[decodeURIComponent(element[0])] = decodeURIComponent(element[1] || "")
                // console.info(decodeURIComponent(element[0]) + " = " + decodeURIComponent(element[1]));
            }
            return paramsObject
        }(parameters)

        if (params["lat"] !== undefined && params["lon"] !== undefined){
            sharedCoords = {lat: parseFloat(params["lat"]), lon: parseFloat(params["lon"]), zoom: 10};
            paramsObject = {
                sharedCoords: sharedCoords,
                pickerCoords: undefined
            };
            store.set("locationParsed", true);
            store.set("locationMarker", true);

        }

    }
    else
        paramsObject = parameters.length > 5 && parseSearch(parameters)


    // if (window.history && window.history.replaceState) {
    //     try {
    //         // var e = getPrefix();
    //         window.history.pushState({
    //             url: ""
    //         }, "", window.location.pathname);
    //
    //         // setCanonical(e)
    //     } catch (e) {
    //     }
    // }
}

// var p = resolveRoute(purl);
//
// if ( p ) {
//     broadcast.once("paramsChanged",
//         function() {
//             return broadcast.emit("rqstOpen", p.plugin, p.params || paramsObject)
//         });
//     rootScope.startupDetail = p.params || p.plugin;
//     purl = seoParser.startupUrl
// } else
//     purl = "";
//
// if (  ( ! p || ! p.plugin ) && pickerCoords && sharedCoords  ) {
//     pickerCoords.noEmit = true,
//     broadcast.once("redrawFinished", broadcast.emit.bind(broadcast, "rqstOpen", "picker", pickerCoords))
// }

export default {
    url: purl,
    lang: lang,
    url404: url404,
    sharedCoords: sharedCoords,
    parseSearch: parseSearch//,
    // resolveRoute: resolveRoute
}
