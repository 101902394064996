import store from "./store";
import metrics from "./metrics";
import geolocation from "./geolocation";
import utils from "./utils";
import $ from "./$.js";
import models from "./models";
import broadcast from "./broadcast";
import countriesCodes from "./countriesCodes";
import rootScope from "./rootScope";

// Si el objeto vientSettings no está definido, pero existe la interfaz de Android
if (typeof vientySettings === "undefined"
    && typeof ExternalInterface !== "undefined"
    && typeof ExternalInterface.loadSettings !== "undefined" ){
    const settingsStr = ExternalInterface.loadSettings();
    window.vientySettings=JSON.parse(settingsStr);
}


// Si el objeto vientySettings no está definido, pero existe el objeto meteored (web)
if (typeof vientySettings === "undefined"
    && typeof meteored !== "undefined"
    && typeof meteored.vientySettings !== "undefined" ){
    window.vientySettings=meteored.vientySettings;
}

//     firstTime: true,
//     banner: false,
//     lang: 'ro',
//     country: 'RO'
// }


// CONFIGURACIÓN DE ENTRADA
if (typeof vientySettings !== "undefined" ){

    let embed = false;

    // Se deshabilitan los eventos para minimapas (EMBED)
    if (typeof vientySettings.disableEvents !== "undefined" && vientySettings.disableEvents){
        const startscreen = document.querySelector("#startscreen");
        startscreen.classList.add("activo");
        store.set("embed", true);
        embed = true;
    }

    // Se establece si es suscripción premium o NO
    store.set("premium", typeof vientySettings.premium !== "undefined" && vientySettings.premium);

    // Coordenadas del centro del mapa
    let centercoords = vientySettings.coords;

    // Si existe el param centercoords se cogen estas como coordenadas centrales
    if (typeof vientySettings.centercoords !== "undefined")
        centercoords = vientySettings.centercoords;

    // Si centercountry no está definido o es falso
    if (typeof vientySettings.centercountry === undefined || ! vientySettings.centercountry) {
        // Si no se han especificado unas coordendadas centrales se cogen las de por defecto,
        // según el país seleccionado
        if (typeof centercoords === "undefined" && typeof vientySettings.country !== "undefined" && utils.isValidCountry(vientySettings.country)) {
            const country = countriesCodes[vientySettings.country];
            centercoords = {
                lat: country.lat,
                lon: country.lon,
                zoom: rootScope.isMobileOrTablet ? country.zoom_mv : country.zoom_pc
            };
        }

        // Si el overlay es daysymbols y no se ha especificado ningún zoom, ponemos el zoom 7 a piñón
        if ( typeof vientySettings.params !== "undefined" &&
            typeof vientySettings.params.overlay !== "undefined" &&
            vientySettings.params.overlay === "daysymbols" &&
            typeof centercoords !== "undefined" &&
            typeof centercoords.zoom === "undefined" ) {
                centercoords.zoom = 7;
        }


    } else {
        // Debe haberse pasado un país cómo parámetro
        if ( typeof vientySettings.country !== "undefined" && utils.isValidCountry(vientySettings.country)) {
            const country = countriesCodes[vientySettings.country];
            const zoom = rootScope.isMobileOrTablet ? country.zoom_mv : country.zoom_pc;
            // Si no se han especificado coordenadas centrales o el zoom del país es mayor que 4.5 (zoom a partir del cual
            // se ven los simbolos)
            if (typeof centercoords === "undefined" || zoom >= 4.5){
                centercoords = {
                    lat: country.lat,
                    lon: country.lon,
                    zoom: zoom
                };
            }else
                centercoords.zoom = 4.5;
        }
    }

    // COORDENADAS
    if (typeof centercoords !== "undefined") {
        geolocation.setIpLocation(centercoords.lat, centercoords.lon, centercoords.zoom);
    }

    // MARCADOR EN LAS COORDENADAS
    if (typeof vientySettings.locationMarker !== "undefined" && ! store.get("locationParsed") ) {
        store.set("locationMarker", vientySettings.locationMarker);
    }

    // Se ponen las coordenadas coords como las del marcador
    if (typeof vientySettings.coords !== "undefined") {
        store.set("locationMarkerCoords", vientySettings.coords);
    }

    // Comprueba si se activa el polen en el país
    if (typeof vientySettings.country !== "undefined" && utils.isPollenCountry(vientySettings.country)) {
            const button = document.querySelector("#polen");
            const tooltip = document.querySelector("#polentooltip");
            if(button)
                button.classList.add("visible");
            if(tooltip)
                tooltip.classList.add("visible");
    }

    // Comprueba si se activa el radar en el país
    if (typeof vientySettings.country !== "undefined" && utils.isRadarCountry(vientySettings.country)) {
        const button = document.querySelector("#radar");
        const tooltip = document.querySelector("#radartooltip");
        if(button)
            button.classList.add("visible");
        if(tooltip)
            tooltip.classList.add("visible");
    }

    // FORMATO HORAS
    if (typeof vientySettings.is12hFormat !== "undefined" && vientySettings.is12hFormat) {
        const container=$('#visor2') || document.body;
        container.classList.add("time12h");
        store.set("hourFormat", "12h");
    }else {
        store.set("hourFormat", "24h");
    }

    // PAÍS
    if (typeof vientySettings.country !== "undefined" && utils.isValidCountry(vientySettings.country)){
        store.set("country", vientySettings.country);
    }

    // IDIOMA
    if (typeof vientySettings.lang !== "undefined") {
        let lang = vientySettings.lang + "_" + vientySettings.country;
        if (! utils.isValidLang(lang)) {
            const defLang = utils.defaultLang(lang);
            if ( defLang !== undefined )
                lang = defLang;
        }

        store.set("lang", lang);
    }

    // ANIMACIÓN DE PARTÍCULAS (En función del rendimiento)
    if(typeof vientySettings.particlesAnim !== "undefined"){
        store.set("particlesAnim", vientySettings.particlesAnim?"on":"off");
    }

    // HURACANES
    if(typeof vientySettings.hurricanes !== "undefined"){
        store.set("hurricanes", vientySettings.hurricanes?"nopanel":"off")
    }

    // FORMATO HORAS
    if (typeof vientySettings.radarAnimation !== "undefined") {
        store.set("radarAnimation", vientySettings.radarAnimation);
    }

    // RENDIMIENTO DEL DISPOSITIVO
    if (typeof vientySettings.performanceTest !== "undefined" && vientySettings.performanceTest && vientySettings.performanceTest !== "undefined" ) {
        store.set("performanceTest", vientySettings.performanceTest+"_predef", {forceChange: true});
    }

    // Establece el timestamp a las 12:00 (local) del día seleccionado
    if (typeof vientySettings.day !== "undefined"){
        store.set("timestamp", utils.day2ts(vientySettings.day))
    }

    // Si es true esconde la cabecera con el título y subtítulo
    if (typeof vientySettings.hideHeader !== "undefined" &&  vientySettings.hideHeader){
        const header = document.getElementById("desc_mapa");
        header.classList.add("novisible");
    }

    // Si es la primera vez que se accede
    if (typeof vientySettings.firstTime !== "undefined" && vientySettings.firstTime) {
        const startscreen = document.querySelector("#startscreen");

        startscreen.classList.add("activo");

        function removeTooltips(){
            startscreen.classList.remove("activo");
        }

        startscreen.addEventListener("click", removeTooltips);
        startscreen.addEventListener("touchcancel", removeTooltips);
        startscreen.addEventListener("touchend", removeTooltips);
        startscreen.addEventListener("touchmove", removeTooltips);
        startscreen.addEventListener("touchstart", removeTooltips);

    }


    // Si se pinta el banner de publicidad o no
    if (typeof vientySettings.banner !== "undefined" && vientySettings.banner) {
        const logos = document.getElementsByClassName("logo");

        for (let i=0; i< logos.length; i++){
            logos[i].classList.add("disabled");
        }

    }

    // PARÁMETROS
    if (typeof vientySettings.params !== "undefined" && vientySettings.params){

        // Establece el timestamp a las 12:00 (local) del día seleccionado
        // PARCHE para que funcione también si se mete dentro de params
        if (typeof vientySettings.params.day !== "undefined"){
            store.set("timestamp", utils.day2ts(vientySettings.params.day));
            // Para que no de error
            delete vientySettings.params.day;
        }

        // Si es true esconde la cabecera con el título y subtítulo
        if ( typeof vientySettings.params.hideHeader !== "undefined" ) {
            if (vientySettings.params.hideHeader) {
                const header = document.getElementById("desc_mapa");
                header.classList.add("novisible");
            }
            // Para que no de error
            delete vientySettings.params.hideHeader;
        }


        for (const key in vientySettings.params) {
            if (["overlay", "map", "performanceTest"].includes(key) || ! embed )
                store.set(key, vientySettings.params[key])
        }

        // Si el producto no está disponible para el overlay se selecciona uno disponible
        const nameProduct = store.get("product");
        const availProduct = models.getProduct(store.get("overlay"), store.get("product"));
        if (nameProduct !== availProduct)
            store.set("product", availProduct);

    }

    // Establece el día de los avisos (hoy, mañana, pasado mañana) y el título los pone compatibles con los h1 de web
    if (typeof vientySettings.warningsday !== "undefined" && vientySettings.warningsday && store.get("overlay") === "warnings"){

        const today = new Date();
        let todayTs = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), 12, 0, 0, 0)

        if(vientySettings.warningsday === "tomorrow"){
            const tomorrowUTC = new Date(todayTs + 24 * 60 * 60 * 1000);
            const tomorrow = new Date(tomorrowUTC.getUTCFullYear(), tomorrowUTC.getUTCMonth(), tomorrowUTC.getUTCDate(), 12);

            store.set("timestamp", tomorrow.getTime());
        }
        else if (vientySettings.warningsday === "aftertomorrow"){
            const afterTomorrowUTC = new Date(todayTs + 2 * 24 * 60 * 60 * 1000);
            const afterTomorrow = new Date(afterTomorrowUTC.getUTCFullYear(), afterTomorrowUTC.getUTCMonth(), afterTomorrowUTC.getUTCDate(), 12);

            store.set("timestamp", afterTomorrow.getTime());
        }

        store.set("warningsday", vientySettings.warningsday);

    }



    // UNIDADES DE MEDIDA

    // VIENTO
    if (typeof vientySettings.windUnit !== "undefined") {
        const indexUnit = metrics["wind"].appCodes.indexOf(vientySettings.windUnit);
        const unidadStr = metrics["wind"].description[indexUnit];

        store.set("metric_wind", unidadStr);
    }

    // TEMPERATURA
    if (typeof vientySettings.tempUnit !== "undefined") {
        const indexUnit = metrics["temp"].appCodes.indexOf(vientySettings.tempUnit);
        const unidadStr = metrics["temp"].description[indexUnit];

        store.set("metric_temp", unidadStr);
    }

    // LLUVIA
    if (typeof vientySettings.rainUnit !== "undefined") {
        let indexUnit = metrics["rain"].appCodes.indexOf(vientySettings.rainUnit);
        let unidadStr = metrics["rain"].description[indexUnit];

        store.set("metric_rain", unidadStr);

        indexUnit = metrics["rainAccu"].appCodes.indexOf(vientySettings.rainUnit);
        unidadStr = metrics["rainAccu"].description[indexUnit];

        store.set("metric_rainAccu", unidadStr);
    }

    // PRESIÓN
    if (typeof vientySettings.pressureUnit !== "undefined") {
        const indexUnit = metrics["pressure"].appCodes.indexOf(vientySettings.pressureUnit);
        const unidadStr = metrics["pressure"].description[indexUnit];

        store.set("metric_pressure", unidadStr);
    }

    // DISTANCIA Y ALTURA
    if (typeof vientySettings.lenghtUnit !== "undefined") {
        // NIEVE
        let indexUnit = metrics["snow"].appCodes.indexOf(vientySettings.lenghtUnit);
        let unidadStr = metrics["snow"].description[indexUnit];

        store.set("metric_snow", unidadStr);

        // NIEVE ACUMULADA
        indexUnit = metrics["snowAccu"].appCodes.indexOf(vientySettings.lenghtUnit);
        unidadStr = metrics["snowAccu"].description[indexUnit];

        store.set("metric_snowAccu", unidadStr);

        // VISIBILIDAD
        indexUnit = metrics["visibility"].appCodes.indexOf(vientySettings.lenghtUnit);
        unidadStr = metrics["visibility"].description[indexUnit];

        store.set("metric_visibility", unidadStr);

        // OLAS
        indexUnit = metrics["waves"].appCodes.indexOf(vientySettings.lenghtUnit);
        unidadStr = metrics["waves"].description[indexUnit];

        store.set("metric_waves", unidadStr);

        // DENSIDAD DE RAYOS
        indexUnit = metrics["lightDensity"].appCodes.indexOf(vientySettings.lenghtUnit);
        unidadStr = metrics["lightDensity"].description[indexUnit];

        store.set("metric_lightDensity", unidadStr);

        // ALTITUD
        indexUnit = metrics["altitude"].appCodes.indexOf(vientySettings.lenghtUnit);
        unidadStr = metrics["altitude"].description[indexUnit];

        store.set("metric_altitude", unidadStr);

    }



}else{
    let button = document.querySelector("#polen");
    let tooltip = document.querySelector("#polentooltip");
    if(button)
        button.classList.add("visible");
    if(tooltip)
        tooltip.classList.add("visible");

    button = document.querySelector("#radar");
    tooltip = document.querySelector("#radartooltip");
    if(button)
        button.classList.add("visible");
    if(tooltip)
        tooltip.classList.add("visible");

}

// var ExternalInterface={
//     setParam(key, value){
//         console.info(`key: ${key}, value: ${value}`)
//     }
// };

// INTERFACE ANDROID
if (typeof ExternalInterface !== "undefined") {

    ["overlay", "level", "product", "particlesAnim", "isolines", "map", "performanceTest", "openMenus", "openLegend"].forEach(
        function(key){
            store.on(key, function(value, options){
                if (options && ! options.doNotStore) {
                     ExternalInterface.setParam(key, value);
                }
            });

        }
    )
}

// INTERFACE IOS
if (typeof window.webkit !== "undefined") {
    ["overlay", "level", "product", "particlesAnim", "isolines", "map", "performanceTest", "openMenus", "openLegend"].forEach(
        function(key){
            store.on(key, function(value, options){
                if (options && ! options.doNotStore) {
                    let obj = {};
                    obj[key] = value;
                    window.webkit.messageHandlers.vientyMessageHandler.postMessage(obj);
                }
            });

        }
    )
}



// DEFINICIÓN DE FUNCIONES EXTERNAS


window.onChangeWind = (unit) => {
    const indexUnit = metrics["wind"].appCodes.indexOf(unit);
    const unidadStr = metrics["wind"].description[indexUnit];

    store.set("metric_wind", unidadStr);
}


window.onChangeTemp = (unit) => {
    const indexUnit = metrics["temp"].appCodes.indexOf(unit);
    const unidadStr = metrics["temp"].description[indexUnit];

    store.set("metric_temp", unidadStr);
}


window.onChangeRain = (unit) => {
    let indexUnit = metrics["rain"].appCodes.indexOf(unit);
    let unidadStr = metrics["rain"].description[indexUnit];

    store.set("metric_rain", unidadStr);

    indexUnit = metrics["rainAccu"].appCodes.indexOf(unit);
    unidadStr = metrics["rainAccu"].description[indexUnit];

    store.set("metric_rainAccu", unidadStr);
}

window.onChangePress = (unit) => {
    const indexUnit = metrics["pressure"].appCodes.indexOf(unit);
    const unidadStr = metrics["pressure"].description[indexUnit];

    store.set("metric_pressure", unidadStr);
}


window.onChangeHeight = (unit) => {
    // NIEVE
    let indexUnit = metrics["snow"].appCodes.indexOf(unit);
    let unidadStr = metrics["snow"].description[indexUnit];

    store.set("metric_snow", unidadStr);

    // NIEVE ACUMULADA
    indexUnit = metrics["snowAccu"].appCodes.indexOf(unit);
    unidadStr = metrics["snowAccu"].description[indexUnit];

    store.set("metric_snowAccu", unidadStr);

    // VISIBILIDAD
    indexUnit = metrics["visibility"].appCodes.indexOf(unit);
    unidadStr = metrics["visibility"].description[indexUnit];

    store.set("metric_visibility", unidadStr);

    // OLAS
    indexUnit = metrics["waves"].appCodes.indexOf(unit);
    unidadStr = metrics["waves"].description[indexUnit];

    store.set("metric_waves", unidadStr);

    // DENSIDAD DE RAYOS
    indexUnit = metrics["lightDensity"].appCodes.indexOf(unit);
    unidadStr = metrics["lightDensity"].description[indexUnit];

    store.set("metric_lightDensity", unidadStr);

    // ALTITUD
    indexUnit = metrics["altitude"].appCodes.indexOf(unit);
    unidadStr = metrics["altitude"].description[indexUnit];

    store.set("metric_altitude", unidadStr);
}


window.onBackPressed = () => {
    broadcast.emit("backPressed");
}
