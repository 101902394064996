const overlays: {[index: string]: any} = {
    symbols: {
        ident: "symbols",
        trans: "SYMBOLS",
        icon: "iconos_terrestre2_blue/simbolos.svg",
        iconfont: "&#xe80F;",
        layers: ["symbols"]
    },
    daysymbols: {
        ident: "daysymbols",
        trans: "SYMBOLS",
        hidden: true,
        icon: "iconos_terrestre2_blue/simbolos.svg",
        iconfont: "&#xe80F;",
        layers: ["daysymbols"]
    }
    ,
    prcnub : {
        ident: "prcnub",
        trans: "PRCNUB",
        icon: "iconos_terrestre2_blue/lluvia_nubosidad_nieve.svg",
        layers: ["windParticles", "prcnub"]
    }
    ,
    precip: {
        ident: "precip",
        trans: "PREC",
        icon: "iconos_terrestre2_blue/lluvia-nieve.svg",
        iconfont: "&#xe805;",
        layers: ["windParticles","precip"]
    }
    ,
    nieve: {
        ident: "nieve",
        trans: "SNOW",
        icon: "iconos_terrestre2_blue/nieve.svg",
        iconfont: "&#xe808;",
        layers: ["windParticles", "nieve"]
    }
    ,
    nubes: {
        ident: "nubes",
        trans: "CLOUDS",
        iconfont: "&#xe809;",
        icon: "iconos_terrestre2_blue/nubosidad.svg",
        layers: ["windParticles", "nubes"]
    }
    ,
    acumprec: {
        ident: "acumprec",
        trans: "RACCU",
        icon: "iconos_terrestre2_blue/lluvia_acumulada.svg",
        iconfont: "&#xe804;",
        symbols: false,
        layers: ["acumprec"]
    }
    ,
    acumnieve: {
        ident: "acumnieve",
        trans: "SACCU",
        icon: "iconos_terrestre2_blue/nieve-acumulada.svg",
        iconfont: "&#xe82E;",
        symbols: false,
        layers: ["acumnieve"]
    }
    ,
    snowdepth: {
        ident: "snowdepth",
        trans: "SDEPTH",
        icon: "iconos_terrestre2_blue/profundidad-nieve.svg",
        iconfont: "&#xe830;",
        symbols: false,
        layers: ["windParticles", "snowdepth"]
    }
    ,
    isozero: {
        ident: "isozero",
        trans: "ISOZERO",
        icon: "iconos_terrestre2_blue/cota-nieve.svg",
        iconfont: "&#xe82F;",
        layers: ["windParticles", "isozero"]
    }
    ,
    prob: {
        ident: "prob",
        trans: "PPREC",
        icon: "iconos_terrestre2_blue/probabilidad.svg",
        iconfont: "&#xe810;",
        layers: ["prob"]
    }
    ,
    temp2m: {
        ident: "temp2m",
        trans: "TEMPERATURE",
        icon: "iconos_terrestre2_blue/temperatura.svg",
        iconfont: "&#xe80D;",
        layers: ["windParticles", "temp2m"],
        hasMoreLevels: true
    },
    atemp: {
        ident: "atemp",
        trans: "ATEMP",
        icon: "iconos_terrestre2_blue/anomalia-temperatura.svg",
        iconfont: "&#xe801;",
        layers: ["windParticles", "atemp"],
        hasMoreLevels: true
    }
    ,
    viento: {
        ident: "viento",
        hasMoreLevels: true,
        trans: "WIND",
        icon: "iconos_terrestre2_blue/viento.svg",
        iconfont: "&#xe80E;",
        layers: ["windParticles", "viento"]
    }
    ,
    rachas: {
        ident: "rachas",
        trans: "GUST",
        icon: "iconos_terrestre2_blue/rachas-maximas.svg",
        iconfont: "&#xe80A;",
        layers: ["windParticles", "rachas"]
    }
    ,
    rayos: {
        ident: "rayos",
        trans: "THUNDER",
        icon: "iconos_terrestre2_blue/rayos.svg",
        iconfont: "&#xe80B;",
        layers: ["windParticles", "rayos"]
    }
    ,
    humedad: {
        ident: "humedad",
        icon: "iconos_terrestre2_blue/humedad_r.svg",
        iconfont: "&#xe802;",
        trans: "RH",
        layers: ["windParticles", "humedad"],
        hasMoreLevels: true
    }
    ,
    uvi: {
        ident: "uvi",
        trans: "UVI",
        icon: "iconos_terrestre2_blue/indice-uv.svg",
        iconfont: "&#xe803;",
        layers: ["windParticles", "uvi"]
    }
    ,
    waterprec: {
        ident: "waterprec",
        icon: "iconos_terrestre2_blue/agua-precipitable.svg",
        iconfont: "&#xe800;",
        trans: "WATERPREC",
        layers: ["windParticles", "waterprec"],
    },
    ivt: {
        ident: "ivt",
        icon: "iconos_terrestre2_blue/rios-atmosfericos.svg",
        iconfont: "&#xe80C;",
        trans: "IVT",
        layers: ["ivtVectors", "ivt"],
    },
    visibilidad: {
        ident: "visibilidad",
        trans: "VISIBILITY",
        icon: "iconos_terrestre2_blue/visibilidad.svg",
        iconfont: "&#xe80F;",
        layers: ["windParticles", "visibilidad"]
    },
    oleajetotal: {
        ident: "oleajetotal",
        trans: "WAVES",
        icon: "iconos_mar2_blue/oleaje-total.svg",
        iconfont: "&#xe815;",
        parentMenu: "menu-mar",
        hideWxLabels: true,
        layers: ["waveParticles","oleajetotal"]
    }
    ,
    oleajeviento: {
        ident: "oleajeviento",
        trans: "WWAVES",
        icon: "iconos_mar2_blue/mar-viento.svg",
        iconfont: "&#xe814;",
        parentMenu: "menu-mar",
        hideWxLabels: true,
        layers: ["windWaveParticles","oleajeviento"]
    },
    oleajefondo: {
        parentMenu: "menu-mar",
        ident: "oleajefondo",
        trans: "SWAVES",
        icon: "iconos_mar2_blue/mar-fondo.svg",
        iconfont: "&#xe813;",
        hideWxLabels: true,
        layers: ["swellWaveParticles","oleajefondo"]
    },
    oleajealtura: {
        parentMenu: "menu-mar",
        ident: "oleajealtura",
        trans: "HMWAVES",
        icon: "iconos_mar2_blue/altura-mar.svg",
        iconfont: "&#xe812;",
        hideWxLabels: true,
        layers: ["oleajealtura"]
    },
    tempmar: {
        parentMenu: "menu-mar",
        ident: "tempmar",
        trans: "SST",
        icon: "iconos_mar2_blue/temperatura-mar.svg",
        iconfont: "&#xe816;",
        hideWxLabels: true,
        layers: ["tempmar"]
    },
    aqi:{
        parentMenu: "menu-aire",
        ident: "aqi",
        trans: "AQI",
        childkey: "aqioverlay",
        icon: "iconos_aire2_blue/aqi2.svg",
        iconfont: "&#xe817;",
    },
    duaod550: {
        parentMenu: "menu-aire",
        ident: "duaod550",
        trans: "AOD550",
        icon: "iconos_aire2_blue/calima2.svg",
        iconfont: "&#xe818;",
        layers: ["ecmwfWindParticles700h", "duaod550"]
    },
    pm2p5: {
        parentMenu: "menu-aire",
        ident: "pm2p5",
        trans: "PM2P5",
        icon: "iconos_aire2_blue/particulas-2.svg",
        iconfont: "&#xe81E;",
        layers: ["ecmwfWindParticles", "pm2p5"]
    },
    pm10: {
        parentMenu: "menu-aire",
        ident: "pm10",
        trans: "PM10",
        icon: "iconos_aire2_blue/particulas-10.svg",
        iconfont: "&#xe81F;",
        layers: ["ecmwfWindParticles", "pm10"]
    },
    no2: {
        parentMenu: "menu-aire",
        ident: "no2",
        trans: "NO2",
        icon: "iconos_aire2_blue/dioxido-nitrogeno.svg",
        iconfont: "&#xe81B;",
        layers: ["ecmwfWindParticles", "no2"]
    },
    so2: {
        parentMenu: "menu-aire",
        ident: "so2",
        trans: "SO2",
        icon: "iconos_aire2_blue/dioxido-azufre.svg",
        iconfont: "&#xe81A;",
        layers: ["ecmwfWindParticles", "so2"]
    },
    tcso2: {
        parentMenu: "menu-aire",
        ident: "tcso2",
        trans: "TCSO2",
        icon: "iconos_aire2_blue/columna-so2.svg",
        iconfont: "&#xe819;",
        layers: ["ecmwfWindParticles", "tcso2"]
    },
    o3: {
        parentMenu: "menu-aire",
        ident: "o3",
        trans: "O3",
        icon: "iconos_aire2_blue/ozono.svg",
        iconfont: "&#xe81D;",
        layers: ["ecmwfWindParticles", "o3"]
    },
    co: {
        parentMenu: "menu-aire",
        ident: "co",
        trans: "CO",
        icon: "iconos_aire2_blue/monoxido-carbono.svg",
        iconfont: "&#xe81C;",
        layers: ["ecmwfWindParticles", "co"]
    },
    aqimet: {
        parentMenu: "menu-aire",
        parentOverlay: "aqi",
        hidden: true,
        ident: "aqimet",
        trans: "AQIMET",
        hideWxLabels: true,
        icon: "iconos_aire2_blue/aqi2.svg",
        iconfont: "&#xe817;",
        layers: ["ecmwfWindParticles", "aqimet"]
    },
    aqieu: {
        parentMenu: "menu-aire",
        parentOverlay: "aqi",
        hidden: true,
        ident: "aqieu",
        trans: "AQIEU",
        hideWxLabels: true,
        icon: "iconos_aire2_blue/aqi2.svg",
        iconfont: "&#xe817;",
        layers: ["ecmwfWindParticles", "aqieu"]
    },
    aqiusa: {
        parentMenu: "menu-aire",
        parentOverlay: "aqi",
        hidden: true,
        ident: "aqiusa",
        trans: "AQIUSA",
        hideWxLabels: true,
        icon: "iconos_aire2_blue/aqi2.svg",
        iconfont: "&#xe817;",
        layers: ["ecmwfWindParticles", "aqiusa"]
    },
    aqimpol: {
        parentMenu: "menu-polen",
        ident: "aqimpol",
        trans: "AQIMPOL",
        hideWxLabels: true,
        icon: "iconos_polen2_blue/pollen-levels.svg",
        iconfont: "&#xe82B;",
        layers: ["ecmwfWindParticles", "aqimpol"]
    },
    olive: {
        parentMenu: "menu-polen",
        ident: "olive",
        trans: "OLIVE",
        icon: "iconos_polen2_blue/olive-lineal.svg",
        iconfont: "&#xe821;",
        layers: ["ecmwfWindParticles", "olive"]
    },
    alder:{
        parentMenu: "menu-polen",
        ident: "alder",
        trans: "ALDER",
        icon: "iconos_polen2_blue/alder-lineal.svg",
        iconfont: "&#xe825;",
        layers: ["ecmwfWindParticles", "alder"]
    },
    birch: {
        parentMenu: "menu-polen",
        ident: "birch",
        trans: "BIRCH",
        icon: "iconos_polen2_blue/birch-lineal.svg",
        iconfont: "&#xe824;",
        layers: ["ecmwfWindParticles", "birch"]
    },
    grass: {
        parentMenu: "menu-polen",
        ident: "grass",
        trans: "GRASS",
        icon: "iconos_polen2_blue/grass-lineal.svg",
        iconfont: "&#xe823;",
        layers: ["ecmwfWindParticles", "grass"]
    },
    mugwort: {
        parentMenu: "menu-polen",
        ident: "mugwort",
        trans: "MUGWORT",
        icon: "iconos_polen2_blue/mugwort-lineal.svg",
        iconfont: "&#xe822;",
        layers: ["ecmwfWindParticles", "mugwort"]
    },
    ragweed:{
        parentMenu: "menu-polen",
        ident: "ragweed",
        trans: "RAGWEED",
        icon: "iconos_polen2_blue/ragweed-lineal.svg",
        iconfont: "&#xe820;",
        layers: ["ecmwfWindParticles", "ragweed"]
    },
    radar: {
        parentMenu: "menu-radar",
        selfMenu: true,
        buttonId: "radarvisor",
        allwaysOn: true,
        ident: "radar",
        trans: "RADARMAPS",
        icon: "radar_blanco.svg",
        iconfont: "&#xe82A;",
        layers: ["radar"]
    },
    warnings: {
        parentMenu: "menu-warnings",
        selfMenu: true,
        allwaysOn: true,
        showDay: true,
        ident: "warnings",
        trans: "WARNINGSMAPS",
        icon: "warning_blanco.svg",
        iconfont: "&#xe831;",
        layers: ["warnings"]
    },
    warningstoday: {
        ident: "warningstoday",
        trans: "WARNINGSTODAY",
        hidden: true
    },
    warningstomorrow: {
        ident: "warningstomorrow",
        trans: "WARNINGSTOMORROW",
        hidden: true
    },
    warningsaftertomorrow: {
        ident: "warningsaftertomorrow",
        trans: "WARNINGSAFTERTOMORROW",
        hidden: true
    },
    rgb: {
        parentMenu: "menu-sat",
        allwaysOn: true,
        ident: "rgb",
        trans: "RGB",
        icon: "iconos_sat2_blue/natural.svg",
        layers: ["rgb"]
    },
    ir: {
        parentMenu: "menu-sat",
        allwaysOn: true,
        ident: "ir",
        trans: "IR",
        icon: "iconos_sat2_blue/infrared.svg",
        layers: ["ir"]
    },
    vi: {
        parentMenu: "menu-sat",
        allwaysOn: true,
        ident: "vi",
        trans: "VI",
        icon: "iconos_sat2_blue/visible.svg",
        layers: ["vi"]
    },
    wv: {
        parentMenu: "menu-sat",
        allwaysOn: true,
        ident: "wv",
        trans: "WV",
        icon: "iconos_sat2_blue/wv.svg",
        layers: ["wv"]
    }
}


export default overlays;




//

