

let screenWidth, screenHeight, maxDim, minDim,
    userAgent = window.navigator.userAgent,
    platform,
    device;

platform = /android/i.test(userAgent) ? "android" :
    /(iPhone|iPod|iPad)/i.test(userAgent) ? "ios" :
        /windows phone/i.test(userAgent) ? "wphone" :
            "desktop";

screenWidth = window.screen.width;
screenHeight = window.screen.height;
maxDim = Math.max(screenWidth, screenHeight);
minDim = Math.min(screenWidth, screenHeight);

device = maxDim <= 600 || maxDim <= 960 && minDim <= 600 ? "mobile" :
    maxDim <= 1024 || 1024 === minDim && "ios" === platform ? "tablet" :
        "desktop";

// window.addEventListener("load",
//     function() {
//         document.body.classList.add("platform-" + platform);
//         document.documentElement.id = "device-" + device;
//     });

export default {
    platform: platform,
    device: device
};
