/** Clase que controla el comportamiento visual de los menus para cambiar de tipo de mapa */
class OverlaysMenuView {
    /**
     * Elemento div html que contiene los menus
     * @private
     */
    private readonly menu: HTMLElement | null;

    private readonly buttonsMenu: HTMLElement | null;

    /**
     * Función que maneja en el modelo el cambio de overlay al hacer click
     * @private
     */
    private overlayClick: ((idoverlay: string) => any) | undefined | null = null;

    /**
     * Función que notifica cuando se ha abierto o cerrado una ventana
     * @private
     */
    private notify: ((open: boolean, id: string | null ) => any) | undefined | null = null;

    /**
     * Menú abierto en el momento
     * @private
     */
    private openMenu: Element | undefined | null;

    /**
     * Crea e inicializa un objeto
     * @param overlaysMenuElement
     * @param buttonsMenuElement
     */
    constructor( overlaysMenuElement: HTMLElement, buttonsMenuElement: HTMLElement ) {

        // Se obtiene el elemento que contiene los menus
        this.menu = overlaysMenuElement;
        this.buttonsMenu = buttonsMenuElement;

        // Se obtienen los iconos de los diferentes menus
        const iconos_menus: any = document.getElementsByClassName("icono-menu");

        const OverlaysMenu = this;

        const blur = document.getElementsByClassName("blur")[0];

        // Se añade el listener para que se cierren los menús cuando se pincha en cualquier parte de la pantalla (mobile)
        blur.addEventListener("click", () => {
            const event = new Event('closeMenus');
            document.dispatchEvent(event);
        });

        // Se añade el listener para que al hacer click sobre cada icono se abra
        for (const icono_menu of iconos_menus ) {

            if ( ! icono_menu.classList.contains("nomenu") )
                icono_menu.addEventListener("click", () => {

                    let id = icono_menu.id;
                    let menu = OverlaysMenu.menu?.querySelector('#menu-' + id);
                    const menumaps = OverlaysMenu.menu?.querySelector('#menu-maps');
                    // let blur = document.getElementsByClassName("blur")[0];

                    // if(id=="settings")
                    //     console.info(id);

                    menu?.classList.add("open");
                    blur?.classList.add("open");

                    if (id !== "maps" && id !== "settings")
                        menumaps?.classList.add("novisible");

                    if(this.notify)
                        this.notify(true, id);

                    this.openMenu = menu;

                });

        }

        this.openMenu = this.menu?.querySelector('#menu-maps');

        // Icono de cerrar menus
        const iconos_close_menus : any = document.getElementsByClassName(" close");

        // Se añade el listener para cerrar el menu
        for (const icono_close of iconos_close_menus) {
            icono_close.addEventListener("click", this.closeMenus.bind(this) );
        }
        // Icono de back menus
        const iconos_back_menus : any = document.getElementsByClassName(" back");
        // Se añade el listener para cerrar el menu
        for (const icono_back of iconos_back_menus) {
            icono_back.addEventListener("click", ()=>{
                this.closeActiveMenu();
                // const menumaps = OverlaysMenu.menu?.querySelector('#menu-maps');
                // menumaps?.classList.remove("novisible");
                //
                // // icono_back.parentElement.parentElement.classList.remove("open");
                // this.openMenu.classList.remove("open");
            } );
        }

        // Se añade el listener para cerrar el menu cuando se clica en cualquier lado del visor (Solo en versión mobile)
        const  visor  = document.querySelector("#map-container");
        visor?.addEventListener("click", ()=>{ if ( document.body.clientWidth < 768) this.closeMenus()});

        // Se añade el listener para cerrar todos los menús cuando le llega este evento
        document.addEventListener("closeMenus", this.closeMenus.bind(this));

        function setClasses(el: Element ) {
            const isScrollable = el.scrollHeight > el.clientHeight;

            // GUARD: If element is not scrollable, remove all classes
            if (!isScrollable) {
                el.classList.remove('is-bottom-overflowing', 'is-top-overflowing');
                return;
            }

            // Otherwise, the element is overflowing!
            // Now we just need to find out which direction it is overflowing to (can be both)
            const isScrolledToBottom = el.scrollHeight <= el.clientHeight + el.scrollTop;
            const isScroledlToTop = el.scrollTop === 0;
            el.classList.toggle('is-bottom-overflowing', !isScrolledToBottom);
            el.classList.toggle('is-top-overflowing', !isScroledlToTop);
        }

        const tooltips= document.querySelector('#tooltips');
        this.buttonsMenu?.addEventListener('scroll', (e) => {
            const el = e.currentTarget as HTMLElement;
            setClasses(el);
        });

        if (this.buttonsMenu)
            setClasses(this.buttonsMenu);

        if (tooltips)
            setClasses(tooltips);
    }

    /**
     * Establece el manejador que cambia de overlay al hacer click sobre una opción del menú
     *
     * @param handler Función manejadora
     */
    bindOverlayClick(handler: () => any){
        this.overlayClick = handler;
    }

    /**
     * Establece el manejador que notifica cuando se cierra o abre una ventana
     *
     * @param handler Función manejadora
     */
    bindNotify(handler: (open: boolean) => any){
        this.notify = handler;
    }

    /**
     * Borra todas opciones de un menú
     * @param idmenu id del submenú del que se borran las opciones
     */
    remove (idmenu:string) {
        let menu = this.menu?.querySelector(idmenu);

        let ulmenu = menu?.getElementsByClassName("tipos")[0];

        if (ulmenu)
            while (ulmenu.firstChild) {
                ulmenu.removeChild(ulmenu.firstChild);
            }
    }

    // Despresiona un botón
    unpressButton(button: HTMLSpanElement){
        // const icon1: HTMLSpanElement = <HTMLSpanElement>button?.getElementsByClassName("icon1")[0];
        // const icon2: HTMLSpanElement = <HTMLSpanElement>button?.getElementsByClassName("icon2")[0];

        button?.classList.remove("pressed");

        // icon1.style.display = 'flex';
        // icon2.style.display = 'none';
    }

    // Deja presionado un botón
    pressButton(button: HTMLSpanElement){
        // const icon1: HTMLSpanElement = <HTMLSpanElement>button?.getElementsByClassName("icon1")[0];
        // const icon2: HTMLSpanElement = <HTMLSpanElement>button?.getElementsByClassName("icon2")[0];

        button?.classList.add("pressed");

        // icon1.style.display = 'none';
        // icon2.style.display = 'flex';
    }


    /**
     * Actualiza todos los elementos de los menús
     *
     * @param overlays Array que contiene los overlays que se van a ver en los menus
     * @param selectedOverlay Identificador del overlay actualmente seleccionado
     */
    updateElements(overlays:{ident: string, parentMenu: string, buttonId: string, hidden: boolean, children: string[], icon: string, fullname: string, self: boolean}[], selectedOverlay: string) {

        const overlaysmenu=this;

        // Se borran todas las opciones
        this.remove("#menu-terrestre");
        this.remove("#menu-mar");
        this.remove("#menu-aire");
        this.remove("#menu-polen");
        this.remove("#menu-radar");
        this.remove("#menu-sat");
        //
        // // Despresiona un botón
        // function unpressButton(button: HTMLSpanElement){
        //     // const icon1: HTMLSpanElement = <HTMLSpanElement>button?.getElementsByClassName("icon1")[0];
        //     // const icon2: HTMLSpanElement = <HTMLSpanElement>button?.getElementsByClassName("icon2")[0];
        //
        //     button?.classList.remove("pressed");
        //
        //     // icon1.style.display = 'flex';
        //     // icon2.style.display = 'none';
        // }
        //
        // // Deja presionado un botón
        // function pressButton(button: HTMLSpanElement){
        //     // const icon1: HTMLSpanElement = <HTMLSpanElement>button?.getElementsByClassName("icon1")[0];
        //     // const icon2: HTMLSpanElement = <HTMLSpanElement>button?.getElementsByClassName("icon2")[0];
        //
        //     button?.classList.add("pressed");
        //
        //     // icon1.style.display = 'none';
        //     // icon2.style.display = 'flex';
        // }

        overlays.forEach(
            function (overlay) {

                let overlayname = overlay.ident;

                if (! overlay.hidden) {
                    if (overlay.self) {
                        let buttonId: string = overlay.buttonId;
                        if(!buttonId)
                            buttonId = overlayname;
                        const icono_menu: any = document.querySelector("#" + buttonId);

                        if (icono_menu.classList.contains("nomenu")) {

                            // Si está seleccionado se deja presionado
                            if (overlayname === selectedOverlay) {
                                overlaysmenu.pressButton(icono_menu);
                            }

                            const clickfun = () => {
                                let activo = overlaysmenu.menu?.getElementsByClassName("tipo activo")[0];
                                let pressed: HTMLSpanElement = <HTMLSpanElement>overlaysmenu.buttonsMenu?.getElementsByClassName("icono-menu pressed")[0];

                                let typeToChange = overlayname;
                                const typeOrigin = activo?.id;

                                // Si se cambiado de overlay
                                if (typeOrigin !== typeToChange) {
                                    // Se ejecuta el handler con el identificador
                                    if (overlaysmenu.overlayClick)
                                        overlaysmenu.overlayClick(overlayname)

                                }
                                overlaysmenu.closeMenus();

                                const menumaps = overlaysmenu.menu?.querySelector('#menu-maps');
                                menumaps?.classList.remove("novisible");
                            };

                            // Apaño que sirve para eliminar todos los event listener existentes para que cada vez
                            // que se llame a la función update no se añada un event listener nuevo
                            const cloned_icono_menu = icono_menu.cloneNode(true);
                            icono_menu.parentNode.replaceChild(cloned_icono_menu, icono_menu);
                            cloned_icono_menu.addEventListener("click", clickfun);


                        }

                    } else {

                        // Se selecciona el menu al que pertenece el overlay (terrestre, maritimo o aire)
                        let menu = overlaysmenu.menu?.querySelector("#" + overlay.parentMenu);

                        // Se selecciona el botón del menú al que pertenece el overlay
                        let buttonMenu = <HTMLSpanElement>overlaysmenu.buttonsMenu?.querySelector("#" + overlay.parentMenu.replace(/.+-/g, ""));

                        // Se obtiene el elemento <ul>
                        let ulmenu = menu?.getElementsByClassName("tipos")[0];

                        // Se crea elemento <li>
                        let li = document.createElement("li");
                        li.classList.add("tipo");

                        // Si es el overlay seleccionado se le añade la clase de estilo 'activo'
                        if ( overlay.children && overlay.children.includes(selectedOverlay) ||
                            !overlay.children && overlayname === selectedOverlay) {
                            li.classList.add("activo");

                            // Si el botón no estaba presionado se presiona
                            if (!buttonMenu?.classList.contains("pressed")) {
                                overlaysmenu.pressButton(buttonMenu);
                            }
                        }

                        // if ( overlay.children && overlay.children.includes(selectedOverlay)

                        // id del elemento <li>
                        li.id = overlayname;

                        // Se obtiene el icono
                        if (overlay.icon) {
                            const spanIcon = document.createElement("span");
                            spanIcon.classList.add("icono");
                            const img = document.createElement("img");
                            img.src = overlay.icon;
                            img.alt = overlay.fullname;
                            spanIcon.appendChild(img);
                            li.appendChild(spanIcon);
                        }

                        // Se añade el texto de descripción
                        const spanText = document.createElement("span");
                        spanText.classList.add("texto");

                        const overlaydesc = document.createTextNode(overlay.fullname);
                        spanText.appendChild(overlaydesc);

                        const clickfun = () => {

                            let activo = overlaysmenu.menu?.getElementsByClassName("tipo activo")[0];
                            // let pressed: HTMLSpanElement = <HTMLSpanElement>overlaysmenu.buttonsMenu?.getElementsByClassName("icono-menu pressed")[0];

                            const typeOrigin = activo?.id;
                            const typeToChange = li.id;

                            // const buttonid = overlay.parentMenu.replace(/.+-/g, "");

                            // Si se cambiado de overlay
                            if (typeOrigin !== typeToChange) {

                                // Se ejecuta el handler con el identificador
                                if (overlaysmenu.overlayClick)
                                    overlaysmenu.overlayClick(li.id); //{

                            }
                            overlaysmenu.closeMenus();

                            const menumaps = overlaysmenu.menu?.querySelector('#menu-maps');
                            menumaps?.classList.remove("novisible");
                        }

                        // Se añade el listener al hacer click sobre el <li>
                        li.addEventListener("click", clickfun);

                        li.appendChild(spanText);
                        ulmenu?.appendChild(li);
                    }
                }
            }
        );
    }

    changeButton(selectedOverlay: string, parentButtonId: string){
        let selectedLi =this.menu?.querySelector("#"+selectedOverlay);
        let selectedParentLi =this.menu?.querySelector("#"+parentButtonId);
        let activos = this.menu?.getElementsByClassName("tipo activo");
        let activosParent = this.menu?.getElementsByClassName("icono-menu activo");
        // let pressed: HTMLSpanElement = <HTMLSpanElement>this.buttonsMenu?.getElementsByClassName("icono-menu pressed")[0];
        // let buttonMenu = <HTMLSpanElement>this.buttonsMenu?.querySelector("#" + parentButtonId);

        Array.prototype.forEach.call(activos, (activo: HTMLLIElement) => {
            activo?.classList.remove("activo");
        })

        Array.prototype.forEach.call(activosParent, (activo: HTMLLIElement) => {
            activo?.classList.remove("activo");
        })

        // if (activos)
        //     for (let i = 0; i < activos.length; i++){
        //         const activo: HTMLLIElement = <HTMLLIElement> activos[i];
        //         console.info(activo.id);
        //         activo?.classList.remove("activo");
        //     }

        selectedLi?.classList.add("activo");

        if (selectedLi?.id != parentButtonId){
            selectedParentLi?.classList.add("activo");
        }

        // if (pressed?.id !== parentButtonId) {
        //     this.unpressButton(pressed);
        //     this.pressButton(buttonMenu);
        // }

    }


    /**
     * Cierra los menus abiertos
     */
    closeMenus() {
        let menus:any = this.menu?.getElementsByClassName("menu");
        let blur = document.getElementsByClassName("blur")[0];
        blur.classList.remove("open");

        for (const menu of <any> menus) {
            if ( ! menu.classList.contains("submenu") )
                menu.classList.remove("open");
        }

        // Con este timeout los submenús se cierran cuando se ha cerrado el menú principal
        setTimeout(()=>{
                for (const menu of <any> menus) {
                    if ( menu.classList.contains("submenu") )
                        menu.classList.remove("open");
                }
            }, 500
        )


        if (this.notify)
            this.notify(false, null);

    }

    closeActiveMenu(){
        const menumaps = this.menu?.querySelector('#menu-maps');
        menumaps?.classList.remove("novisible");

        // icono_back.parentElement.parentElement.classList.remove("open");
        if (this.openMenu) {
            if (this.openMenu.id === "menu-maps" || this.openMenu.id === "menu-settings" || this.openMenu.id === "menu-search")
                this.closeMenus();
            else
                this.openMenu.classList.remove("open");
        }
        this.openMenu = menumaps;
    }


}

export default OverlaysMenuView;
