

class SearchWindowView {

    private searchWindow: HTMLElement;
    private inputSearch: HTMLInputElement | null = null;
    private search: null | ( (text: string) => any) = null;
    private ulResults: HTMLUListElement | null = null;
    private centerCoords: null | ((city: { title: string; subtitle: string; flag: string; coords: { lon: number; lat: number } }) => any ) = null;

    /**
     * Función que notifica cuando se ha abierto o cerrado una ventana
     * @private
     */
    private notify: ((open: boolean) => any) | undefined | null = null;
    // private getServer:  () => string;

    constructor(searchWindowElement: HTMLElement) {
        this.searchWindow = <HTMLElement> searchWindowElement;
        if (this.searchWindow){
            this.inputSearch = this.searchWindow.querySelector("#inputsearch");
            if (this.inputSearch){
                this.inputSearch.addEventListener("input",(event)=>{
                     // if (event.target )
                    // console.info((event.target as HTMLInputElement).value);
                    const searchText = (event.target as HTMLInputElement).value;
                    if(searchText.length >= 3 && this.search)
                        this.search(searchText);
                })
            }

            const ulResultsElements = this.searchWindow.getElementsByClassName("resultados");
            if(ulResultsElements){
                this.ulResults = <HTMLUListElement> ulResultsElements[0];

            }


        }
    }

    close () {
        let blur = document.getElementsByClassName("blur")[0];
        blur.classList.remove("open");

        this.searchWindow.classList.remove("open");

        if (this.notify)
            this.notify(false);
    }

    bindInputText(handler: (text: string) => any){
        this.search = handler;
    }

    bindCenterCoords(handler: (city: {title: string, subtitle: string, flag: string, coords: {lon: number, lat: number}}) => any){
        this.centerCoords = handler;
    }

    bindGetServer(handler: () => string) {
        this.getServer = handler;
    }

    private getServer(){
        return "";
    }

    update(results:{title: string, subtitle: string, flag: string, coords: {lon: number, lat: number}}[]){

        const server = this.getServer();
        const urlPin =server + "css/svgs/iconos_search/pin.svg";

        if (this.ulResults)
            while (this.ulResults.firstChild) {
                this.ulResults.removeChild(this.ulResults.firstChild);
            }

        for (let result of results){
            if(result.coords) {
                const liElement = document.createElement("li");

                liElement.insertAdjacentHTML("beforeend", `
                <span class="cabecera">
                    <img src="${urlPin}" alt="pin">
                    <span class="textos">
                        <span class="titulo">${result.title}</span>
                        <span class="subtitulo">${result.subtitle}</span>
                    </span>
                </span>
                <span class="flag">${result.flag}</span>
            `);


                liElement.addEventListener("click", () => {
                    if (this.centerCoords)
                        this.centerCoords(result);
                    this.close();
                });

                this.ulResults?.appendChild(liElement);
            }
        }

    }

    /**
     * Establece el manejador que notifica cuando se cierra o abre una ventana
     *
     * @param handler Función manejadora
     */
    bindNotify(handler: (open: boolean) => any){
        this.notify = handler;
    }


}


export default SearchWindowView;
