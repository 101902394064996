import Evented from "./Evented";
import http from "./http";
import store from "./store";
import countriesCodes from "./countriesCodes";
import rootScope from "./rootScope";

const Search = Evented.extend({

    _init(){
        Evented._init.call(this);
        let lang = store.get("lang");
        lang = (lang === "auto") ? "en_US" : lang;
        const countryISO = store.get("country");
        const countryCode = countriesCodes[countryISO].code;

        this.searchurl = `${rootScope.searchURL}/txt/es/${lang}/`;
        // this.countryUrl = `https://local-services.meteored.com/app/search/weather/v4/txt/${countryCode}/${lang}/`;
        this.countryUrl = `${rootScope.searchURL}/top/es/${lang}/${countryCode}.json`

    },
    searchCountry(){
    // , {headers:{ meteored: "loquesea"}}
        http.get(this.countryUrl).then(
            (response) => {
                const resultados = response.data.data.respuesta.localizacion[0].search.resultados;
                this.emit("foundresults", resultados);
            }
        );
    },
    search: function (searchtext) {
        const searchUrl = this.searchurl + searchtext + "/";

        http.get(searchUrl).then(
            (response) => {
                const resultados = response.data.data.respuesta.localizacion[0].search.resultados;
                this.emit("foundresults", resultados);
                // console.info(resultados);
            }
        );

    }
})

export default Search.instance();
