import search from "./search";
import geolocation from "./geolocation";
import broadcast from "./broadcast";
import store from "./store";
import OverlaysMenuView from "../ui/OverlaysMenuView";
import rootScope from "./rootScope";
import utils from "./utils";


class SearchWindowController {

    constructor(view){
        this.view = view;
        this.view.bindInputText(this.search.bind(this));
        this.view.bindCenterCoords(this.centerCoords.bind(this));
        this.view.bindNotify(this.notify.bind(this));
        this.view.bindGetServer(()=>{return rootScope.resourcesServer});


        search.on("foundresults", this.updateResults.bind(this));

        search.searchCountry();

    }

    search(text){
        search.search(text);
    }

    centerCoords(info){
        // console.info(info.coords)
        geolocation.setIpLocation(info.coords.lat, info.coords.lon, 8);
        broadcast.emit("rqstOpen", "picker", info.coords);
    }

    updateResults(results){
        this.view.update(
            results.map(
                (result)=>{
                    return {
                        title: result.name,
                        subtitle: result.description,
                        flag: result.spec.pais.bandera,
                        coords: result.spec.coordenadas
                    };
                }
            )
        )

    }

    /**
     * Método que notifica la apertura o cierre de la ventana de busqueda.
     * @param {boolean} open - Estado de apertura de la ventana.
     * @private
     */
    notify(open){
        // Lanza eventos según el estado de apertura de la ventana.
        if (open){
            const event = new Event('overlayMenuOpened');
            document.dispatchEvent(event);
        }else{
            const event = new Event('overlayMenuClosed');
            document.dispatchEvent(event);
        }

        // Almacena el estado de apertura de la ventana.
        store.set("openMenus", open);
    }


}

export default SearchWindowController;
