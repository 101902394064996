import rootscope from "./rootScope";
import utils from "./utils";

var booleans = [true, false],  // valores permitidos 1 o 0
    isANumber = function(n) {  // es de tipo número y no es NAN
        return "number" == typeof +n && !isNaN(+n)
    },
    isAObject = function(o) {
        return void 0 !== o && "object" == typeof o
    },
    isValidLocation = function(p) {
        return utils.isValidLatLonObj(p) || null == p
    },
    array2str = function(e) {
        return e.slice().sort().toString()
    },
    comparearrays = function(e, t) {
        return array2str(e) === array2str(t)
    },
    isValidLatLonObj = function(e) {
        return utils.isValidLatLonObj(e) || null == e
    },
    isValidDay = function(n) {
        return isANumber(n) && n>=0 && n<=7;
    }

    ;

export default {
    overlay: {
        def: "prcnub",
        allowed: rootscope.overlays,
        save: 0,
        sync: 0
    },
    aqioverlay: {
        def: "aqimet",
        allowed: rootscope.aqioverlays,
        save: true,
        sync: true
    },
    level: {
        def: "surface",
        allowed: rootscope.levels
    },
    // acTime: {
    //     def: "next3d",
    //     allowed: rootscope.acTimes
    // },
    performanceTest:{
        def: "undefined",
        allowed: ["undefined", "low", "low_predef", "high", "high_predef"],
        save: true,
        sync: true
    },
    performanceTestTries:{
        def: 0,
        save: true,
        allowed: isANumber
    },
    timestamp: {
        def: Date.now(),
        // def: new Date(2021,2,24,14,32,6).getTime(),
        // def: new Date(2020,9,9,14,32,6).getTime(),
        // def: new Date(2020,9,8,14,32,6).getTime(),
        allowed: isANumber
    },
    fakeTimestamp: {
        def: Date.now(),
        // def: new Date(2021,2,24,14,32,6).getTime(),
        // def: new Date(2020,9,9,14,32,6).getTime(),
        // def: new Date(2020,9,8,14,32,6).getTime(),
        allowed: isANumber
    },
    path: {
        def: "",
        allowed: function(e) {
            return void 0 !== e && "string" == typeof e
        }
    },
    nextpath: {
        def: "",
        allowed: function(e) {
            return void 0 !== e && "string" == typeof e
        }
    },

    pasada: {
        def: "",
        allowed: isANumber
    },

    proyeccion: {
        def: "",
        allowed: isANumber
    },

    nextproy: {
        def: "",
        allowed: isANumber
    },

    premium: {
      def: false,
      allowed: booleans
    },

    embed: {
      def: false,
      allowed: booleans
    },

    isolines: {
        def: "off",
        allowed: rootscope.isolines,
        save: true
    },
    hurricanes: {
        def: "off",
        allowed: ["nopanel", "on", "off"]
    },
    warnings: {
        def: "off",
        allowed: ["on", "off"]
    },
    warningsday: {
        def: "off",
        allowed: ["off", "today", "tomorrow", "aftertomorrow"]
    },
    // day: {
    //     def: 1,
    //     allowed: isValidDay
    // },
    product: {
        def: "ecmwf",
        allowed: rootscope.products
    },
    availProducts: {
        def: ["ecmwf"],
        allowed: Array.isArray,
        compare: comparearrays
    },
    visibleProducts: {
        def: ["ecmwf"],
        allowed: Array.isArray,
        compare: comparearrays
    },
    // availAcTimes: {
    //     def: ["next12h"],
    //     allowed: Array.isArray
    // },
    prefferedProduct: {
        def: "ecmwf",
        allowed: ["ecmwf", "gfs"]
    },
    animation: {
        def: false,
        allowed: booleans
    },
    calendar: {
        def: null,
        allowed: isAObject
    },
    availLevels: {
        def: rootscope.levels,
        allowed: rootscope.levels
    },
    particlesAnim: {
        def: "on",
        allowed: ["on", "off", "intensive"],
        save: true
    },
    lastModified: {
        def: 0,
        allowed: isANumber
    },
    locationMarker: {
        def: true,
        allowed: booleans
    },
    locationParsed: {
        def: false,
        allowed: booleans
    },
    locationMarkerCoords:{
        def: null,
        allowed: isValidLatLonObj
    },
    openMenus:{
        def: false,
        allowed: booleans
    },
    openLegend:{
        def: false,
        allowed: booleans
    },

    // graticule: {
    //     def: !1,
    //     allowed: n,
    //     save: !0,
    //     sync: !1
    // },
    latlon: {
        def: false,
        allowed: booleans,
        save: true,
        sync: false
    },
    lang: {
        def: "auto",
        allowed: function(e) {
            return "auto" === e || utils.isValidLang(e)
        },
        save: true,
        sync: true
    },
    // englishLabels: {
    //     def: !1,
    //     allowed: n,
    //     save: !0,
    //     sync: !0
    // },
    numDirection: {
        def: false,
        allowed: booleans,
        save: true,
        sync: true
    },
    // favOverlays: {
    //     def: ["radar", "satellite", "wind", "gust", "rain", "rainAccu", "snowAccu", "thunder", "temp", "rh", "clouds", "lclouds", "cbase", "visibility", "waves", "swell1", "swell2", "sst", "no2", "gtco3", "aod550", "pm2p5"],
    //     allowed: Array.isArray,
    //     save: !0,
    //     sync: !1
    // },
    hourFormat: {
        def: "24h",
        allowed: ["12h", "24h"],
        save: true,
        sync: true
    },
    country: {
        def: "ES",
        // save: true,
        allowed: function(e) {
            return /[A-Z][A-Z0-9]/.test(e)
        }
    },
    isImperial: {
         def: false,
         allowed: booleans
    },

    map: {
        def: "light",
        allowed: ["light", "dark", "land"],
        save: true,
        sync: true

    },
    mapCoords: {
        def: null,
        allowed: isValidLatLonObj
    },
    // map: {
    //     def: "sznmap",
    //     allowed: ["sznmap", "sat", "winter"],
    //     save: !0,
    //     sync: !1
    // },
    // showWeather: {
    //     def: !0,
    //     allowed: n,
    //     save: !0,
    //     sync: !1
    // },
    disableWebGL: {
        def: false,
        allowed: [true, false],
        save: true,
        sync: false
    },
    glParticlesOn: {
        def: false,
        allowed: [true, false]
    },
    usedLang: {
        def: "en_US",
        allowed: rootscope.supportedLanguages
    },
    // expertMode: {
    //     def: !1,
    //     allowed: n,
    //     save: !0,
    //     sync: !1
    // },
    // lhpaneSwitch: {
    //     def: "tools",
    //     save: !0,
    //     allowed: ["tools", "favs", "settings"]
    // },
    particles: {
        def: {
            multiplier: 1,
            velocity: 1,
            width: 1,
            blending: 1,
            opacity: 1
        },
        save: true,
        allowed: function(e) {
            var t;
            if (!e || "object" != typeof e)
                return false;
            for (var n in this.def)
                if ("number" != typeof (t = e[n]) || t > 2 || t < 0)
                    return !1;
            return !0
        }
    },
    startUp: {
        def: "ip",
        allowed: ["ip", "gps", "location"],
        save: true
    },
    homeLocation: {
        def: null,
        allowed: isValidLocation,
        save: true,
        sync: true
    },
    ipLocation: {
        def: null,
        allowed: isValidLocation
        // save: true
    },
    gpsLocation: {
        def: null,
        allowed: isValidLocation,
        save: true
    },
    // startupReverseName: {
    //     def: null,
    //     allowed: isAObject,
    //     save: !0
    // },
    // notams: {
    //     def: null,
    //     allowed: isAObject,
    //     save: !0,
    //     sync: !0
    // },
    // email: {
    //     def: "",
    //     allowed: function(e) {
    //         return /\S+@\S+/.test(e)
    //     },
    //     save: !0,
    //     sync: !0
    // },
    // metarsRAW: {
    //     def: !1,
    //     allowed: n,
    //     save: !0,
    //     sync: !0
    // },
    // sessionCounter201803: {
    //     def: 0,
    //     allowed: isANumber,
    //     save: !0
    // },
    // firstUserSession: {
    //     def: 0,
    //     allowed: isANumber,
    //     save: !0
    // },
    // seenRadarInfo: {
    //     def: !1,
    //     save: !0,
    //     allowed: n
    // },
    wasDragged: {
        def: false,
        allowed: booleans,
        save: true,
        sync: true
    },

    pickerLocation: {
        def: null,
        allowed: isValidLocation
    },
    // detailLocation: {
    //     def: null,
    //     allowed: isValidLocation
    // },
    // detailDisplay: {
    //     def: "table",
    //     allowed: ["table", "meteogram", "airgram", "waves", "wind"]
    // },
    // detailProduct: {
    //     def: "ecmwf",
    //     allowed: ["ecmwf", "mblue", "gfs", "iconEu", "ecmwfWaves", "gfsWaves", "namConus", "namAlaska", "namHawaii", "arome"]
    // },
    // detailAvailProducts: {
    //     def: ["ecmwf"],
    //     allowed: Array.isArray,
    //     compare: comparearrays
    // },
    // detailExtended: {
    //     def: !1,
    //     allowed: n
    // },
    // webcamsDaylight: {
    //     def: !1,
    //     allowed: n
    // },
    // capColorizeType: {
    //     def: "severity",
    //     allowed: ["severity", "type"]
    // },
    // capDisplay: {
    //     def: "all",
    //     allowed: ["all", "today", "tomm", "later"]
    // },
    // radarRange: {
    //     def: "-1",
    //     allowed: ["-12", "-6", "-1"]
    // },
    radarTimestamp: {
        def: Date.now() ,
        allowed: isANumber
    },
    radarSpeed: {
        def: "medium",
        allowed: ["slow", "medium", "fast"]
    },
    radarCalendar: {
        def: null,
        allowed: isAObject
    },
    radarAnimation: {
        def: false,
        allowed: booleans
    },
    // blitzOn: {
    //     def: !0,
    //     allowed: n
    // },
    // blitzSoundOn: {
    //     def: !0,
    //     allowed: n
    // },
    radarRange: {
        def: "1",
        allowed: rootscope.radarRange
    },
    satelliteRange: {
        def: "2",
        allowed: rootscope.satelliteRange
    },
    satelliteTimestamp: {
        def: Date.now(),
        allowed: isANumber
    },
    satelliteCalendar: {
        def: null,
        allowed: isAObject
    },
    // satelliteCalendar2: {
    //     def: {},
    //     allowed: isAObject
    // },
    // satelliteAnimation: {
    //     def: !1,
    //     allowed: n
    // },
    // satelliteAnimation2: {
    //     def: !1,
    //     allowed: n
    // },
    // satelliteMode: {
    //     def: "VISIR",
    //     allowed: ["VISIR", "IR", "IRBT"]
    // },
    // satelliteSpeed: {
    //     def: "medium",
    //     allowed: ["slow", "medium", "fast"]
    // },
    // hpShown: {
    //     def: !1,
    //     allowed: n
    // },
    pois: {
        def: "cities",
        allowed: Object.keys(rootscope.pois),
        save: true,
        sync: false
    },
    poisTemporary: {
        def: "empty",
        allowed: Object.keys(rootscope.pois)
    }
    // favPois: {
    //     def: ["favs", "wind", "temp", "cities", "metars", "cams", "pgspots"],
    //     allowed: function(e) {
    //         return Array.isArray(e) && rootscope.length < 8
    //     },
    //     save: !0,
    //     sync: !0
    // },
    // visibility: {
    //     def: !0,
    //     allowed: n
    // },
    // displayLocation: {
    //     def: !0,
    //     allowed: n,
    //     save: !0
    // },
    // vibrate: {
    //     def: !0,
    //     allowed: n,
    //     save: !0
    // },
    // donations: {
    //     def: [],
    //     allowed: Array.isArray,
    //     compare: comparearrays,
    //     save: !0,
    //     sync: !0
    // },
    // zuluMode: {
    //     def: !1,
    //     allowed: n,
    //     save: !0
    // },
    // plugins: {
    //     def: [],
    //     allowed: Array.isArray,
    //     compare: comparearrays,
    //     save: !0,
    //     sync: !0
    // },
    // rplannerDir: {
    //     def: "horizontal",
    //     allowed: ["horizontal", "vertical", "north"],
    //     save: !0
    // },
    // stationsSort: {
    //     def: "profi",
    //     allowed: ["profi", "distance"],
    //     save: !0
    // }
    }
