import layers from "./layers";
import trans from "./trans";
import Class from "./Class";
import colors from "./colors";
import metrics from "./metrics";

// define(["layers", "trans", "Class"], function(layers, t, Class) {
export default Class.extend({
    ident: "",
    trans: null,
    preferredMap: "light",
    hasMoreLevels: false,
    parentMenu: "menu-terrestre",
    selfMenu: false,
    hidden: false,
    poiInCities: true,
    symbols: true,
    _init: function() {
        var layer = layers[this.ident];

        if (layer) {
            var metric = layer.m;
            if ( metric ) {
                this.convertValue = metric.convertValue.bind(metric);
                this.convertNumber = metric.convertNumber.bind(metric); //
                this.setMetric = metric.setMetric.bind(metric);
                this.cycleMetric = metric.cycleMetric.bind(metric);
                this.listMetrics = metric.listMetrics.bind(metric);
                this.m = metric;
            }
            this.c = layer.c;
            this.l = layer.l;
            this.legends = layer.legends;
            this.levels = layer.levels;
            this.daily = layer.daily;
        }

    },
    // paintLegend: function(element) {
    //
    //     if (this.legends){
    //         var nlegend = 1; //, legends = this.legends;
    //
    //         this.legends.forEach(function (layerName){
    //
    //             var layer = layers[layerName];
    //
    //             var newDiv = document.createElement("div");
    //             newDiv.id = "bar"+nlegend;
    //
    //             if (layer.m && layer.m.description)
    //                 layer.m.renderLegend(layer.c, newDiv, layer.l || layer.m, layer.getName());
    //             else
    //                 newDiv.innerHTML = "";
    //
    //             element.insertBefore(newDiv, element.firstChild);
    //             nlegend++;
    //
    //         });
    //
    //         // element.classList.add("bars_"+(nlegend - 1));
    //
    //     }
    //     else if ( this.m && this.m.description )
    //         this.m.renderLegend(this.c, element, this.l || this.m, this.getName());
    //     else
    //         element.innerHTML = "";
    //
    // },
    getDefaultValue: function (values){
        return values[0];
    },
    getColor: function() {
        return this.c && this.c.getColor()
    },
    getName: function() {
        return trans[this.trans] || this.ident
        // return this.ident
    },
    // getCoverWholeMap: function(){
    //     var cover = false;
    //     this.layers.forEach(
    //         function (layername) {
    //             var layer = layers[layername];
    //             cover = cover || layer.getCoverWholeMap();
    //             // console.info(layer.ident + ": " + layer.getCoverWholeMap());
    //         }
    //     );
    //     return cover;
    // }

    createPickerHTML: function (values, t) {
        if (this.convertValue && this.m) {
            let metric, nmetrics,
                value = this.getDefaultValue(values),
                convertedValue;
                // a = (null === (i = this.m) || void 0 === i ? void 0 : i.howManyMetrics()) > 1 ? this.convertValue(r, " <span>", "</span>") : this.convertValue(r, " ");

            metric = this.m;

            if ( ! metric )
                nmetrics = void 0;
            else
                nmetrics = metric.howManyMetrics();

            if (nmetrics > 1)
                convertedValue = this.convertValue(value, " <span>", "</span>");
            else
                convertedValue = this.convertValue(value, " ");

            return `<div class="block">
                        ${this.createPickerTitle()}
                        <div class="p-metric" data-do="changeMetric">${convertedValue}
                        </div>
                    </div>`;
        }
        return ""
    },

    createPickerInvalidHTML: function() {
        return "".concat(this.createPickerTitle(), '<div class="not-avbl">').concat(trans.DATA_NOT_AVBL, "</div>")
    },

    createPickerTitle: function(e) {
        // return e ? '<div class="p-title inlined">'.concat(e, "</div>") : '<div class="p-title inlined" data-icon-after="'.concat(this.icon, '">').concat(this.getName(), "</div>")
        return e ? '<div class="p-title inlined">'.concat(e, "</div>") : '<div class="p-title inlined" data-icon-before="'.concat(this.iconfont?this.iconfont:"", '">' ).concat(this.getName(), "</div>")
        // return e ? '<div class="p-title inlined">'.concat(e, "</div>") : '<div class="p-title inlined" ><img alt="kkkkk" src="css/svgs/iconos_terrestre/temperatura-blue.svg"><p>'.concat(this.getName(), '</p></div>');
    }



})
